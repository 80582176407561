import React, { useState, useEffect } from 'react';
import { Box, useMediaQuery, useTheme, CssBaseline } from '@mui/material';
import Navbar from './Navbar';
import Sidebar from './Sidebar';

const SIDEBAR_WIDTH = 0; // Set a fixed width for the sidebar

export default function Layout({ children }) {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [isSidebarOpen, setIsSidebarOpen] = useState(isLargeScreen);

  useEffect(() => {
    setIsSidebarOpen(isLargeScreen);
  }, [isLargeScreen]);

  const toggleSidebar = () => {
    setIsSidebarOpen(prev => !prev);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        background: `linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, ${theme.palette.primary.dark} 40%, ${theme.palette.secondary.main} 100%)`,
        backgroundAttachment: 'fixed',
      }}
    >
      <CssBaseline />
      <Navbar toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
      <Sidebar open={isSidebarOpen} onToggle={toggleSidebar} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: { xs: '56px', sm: '64px' },
          p: 3,
          transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          marginLeft: {
            xs: 0,
            md: isSidebarOpen ? `${SIDEBAR_WIDTH}px` : 0,
          },
          width: {
            xs: '100%',
            md: `calc(100% - ${isSidebarOpen ? SIDEBAR_WIDTH : 0}px)`,
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
}