import axios from 'axios';

const API_BASE_URL = 'https://www.tutawin.com/api';

// Configuración de headers para distinguir las solicitudes del frontend
const axiosConfig = {
  headers: {
    'x-requested-with': 'TutawinFrontend', // Header personalizado para verificar solicitudes del frontend
  },
};

// Función para registrar la visita
export const registerVisit = async () => {
  try {
    const response = await axios.post(`${API_BASE_URL}/visit/register`, null, axiosConfig);
    return response.data;
  } catch (error) {
    console.error('Error al registrar la visita:', error);
    throw error;
  }
};

// Función para actualizar la actividad de la visita
export const updateActivity = async () => {
  try {
    const response = await axios.post(`${API_BASE_URL}/visit/update-activity`, null, axiosConfig);
    return response.data;
  } catch (error) {
    console.error('Error al actualizar la actividad:', error);
    throw error;
  }
};

// Función para obtener el total de visitas
export const fetchTotalVisits = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/visit/visits`, axiosConfig);
    return response.data.totalVisits;
  } catch (error) {
    console.error('Error al obtener las visitas generales:', error);
    throw error;
  }
};

// Función para obtener el número de visitantes en tiempo real
export const fetchOnlineVisitors = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/visit/online`, axiosConfig);
    return response.data.onlineVisitors;
  } catch (error) {
    console.error('Error al obtener los visitantes en línea:', error);
    throw error;
  }
};
