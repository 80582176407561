import React, { useState, useEffect } from 'react';
import { Typography, Alert, Grid, Card, CardContent, Box, Skeleton, useMediaQuery, useTheme, Divider, Paper, List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getPega3MasLeidsa, getQuinielaLoteka, getLotoPoolLeidsa, getSuperKinoTVLeidsa, getLotomasLeidsa, getSuperPaleLeidsa } from '../services/api';
import CasinoIcon from '@mui/icons-material/Casino';
import EventIcon from '@mui/icons-material/Event';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import Looks6Icon from '@mui/icons-material/Looks6';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import InfoIcon from '@mui/icons-material/Info';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease-in-out',
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  background: 'linear-gradient(145deg, #ffffff, #f0f0f0)',
  boxShadow: '2px 2px 5px #d1d1d1, -2px -2px 5px #ffffff', // Reduced shadow intensity
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '4px 4px 10px #c1c1c1, -4px -4px 10px #ffffff', // Reduced hover shadow intensity
  },
}));

const CardHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  background: 'linear-gradient(45deg, #4CAF50, #2E7D32)',
  color: theme.palette.common.white,
  textAlign: 'center',
}));

const ResultNumber = styled(Box)(({ theme, customcolor }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40px',
  height: '40px',
  margin: theme.spacing(0.5),
  fontSize: '1.2rem',
  fontWeight: 'bold',
  backgroundColor: customcolor || theme.palette.primary.main,
  color: theme.palette.common.white,
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'scale(1.1)',
    backgroundColor: customcolor 
      ? (customcolor === '#2196F3' ? '#1976D2' : customcolor === '#f44336' ? '#d32f2f' : theme.palette.primary.dark)
      : theme.palette.primary.dark,
  },
}));

const DateChip = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  padding: theme.spacing(0.5, 1),
  marginTop: theme.spacing(2),
  backgroundColor: '#f0f0f0',
  border: '1px dashed #4CAF50',
  borderRadius: '16px',
  color: theme.palette.text.primary,
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(0.5),
  },
}));

const InfoPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.spacing(2),
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[10],
  },
}));

const GameTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
}));

const Leidsa = () => {
  const [results, setResults] = useState({
    pega3Mas: [],
    quiniela: [],
    lotoPool: [],
    superKino: [],
    lotoSuperLotoMas: [],
    superPale: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const [pega3Mas, quiniela, lotoPool, superKino, lotoSuperLotoMas, superPale] = await Promise.all([
          getPega3MasLeidsa(),
          getQuinielaLoteka(),
          getLotoPoolLeidsa(),
          getSuperKinoTVLeidsa(),
          getLotomasLeidsa(),
          getSuperPaleLeidsa(),
        ]);
        setResults({ pega3Mas, quiniela, lotoPool, superKino, lotoSuperLotoMas, superPale });
      } catch (error) {
        setError('Error al obtener los resultados de Leidsa. Intenta nuevamente más tarde.');
        console.error('Error fetching Leidsa results:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, []);

  const renderLotteryCard = (title, data) => (
    <Grid item xs={12} sm={6} lg={4}>
      <StyledCard>
        <CardHeader>
          <Typography variant="h6" component="h2" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CasinoIcon sx={{ mr: 1 }} />
            {title}
          </Typography>
        </CardHeader>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
          {loading ? (
            <>
              <Skeleton variant="text" width="60%" height={24} />
              <Skeleton variant="text" width="40%" height={24} />
              <Box display="flex" flexWrap="wrap" justifyContent="center" mt={2}>
                {[...Array(5)].map((_, idx) => (
                  <Skeleton key={idx} variant="rectangular" width={40} height={40} style={{ margin: 4, borderRadius: '8px' }} />
                ))}
              </Box>
            </>
          ) : data.length === 0 ? (
            <Typography variant="body2">No hay resultados disponibles.</Typography>
          ) : (
            data.map((result, index) => (
              <Box key={index} mb={3} sx={{ textAlign: 'center', width: '100%' }}>
                <Typography variant="subtitle1" color="primary" gutterBottom sx={{ fontWeight: 'bold' }}>
                  {result.name}
                </Typography>
                <Box display="flex" flexWrap="wrap" justifyContent="center" mb={2}>
                  {result.numbers.map((number, idx) => {
                    let customcolor = undefined;
                    let tooltipText = '';
                    if (title === 'Loto - Super Loto Más') {
                      if (idx === result.numbers.length - 2) {
                        customcolor = '#2196F3'; // Blue color
                        tooltipText = 'Loto Mas';
                      } else if (idx === result.numbers.length - 1) {
                        customcolor = '#f44336'; // Red color
                        tooltipText = 'Super Loto';
                      }
                    }
                    return (
                      <Tooltip key={idx} title={tooltipText} arrow>
                        <ResultNumber customcolor={customcolor} sx={{ fontSize: isSmallScreen ? '1rem' : '1.2rem' }}>
                          {number}
                        </ResultNumber>
                      </Tooltip>
                    );
                  })}
                </Box>
                <DateChip>
                  <EventIcon fontSize="small" />
                  <Typography variant="caption">{result.date}</Typography>
                </DateChip>
              </Box>
            ))
          )}
        </CardContent>
      </StyledCard>
    </Grid>
  );

  const renderHowToPlay = () => {
    const gameInfo = [
      {
        title: "Pega 3 Más",
        description: "Juego de lotería donde debes acertar 3 números.",
        howToPlay: [
          "Elige 3 números del 0 al 9.",
          "Realiza tu apuesta.",
          "Gana si aciertas los 3 números en el orden correcto."
        ],
        prizes: [
          "Premio mayor por acertar los 3 números en orden.",
          "Premios menores por acertar 2 números o los 3 en diferente orden."
        ]
      },
      {
        title: "Quiniela Leidsa",
        description: "Juego tradicional de lotería con dos sorteos diarios.",
        howToPlay: [
          "Elige un número de 2 cifras del 00 al 99.",
          "Decide el monto de tu apuesta.",
          "Gana si tu número coincide con el resultado del sorteo."
        ],
        prizes: [
          "Premio fijo basado en el monto apostado.",
          "Diferentes premios para primero, segundo y tercer lugar."
        ]
      },
      {
        title: "Loto Pool",
        description: "Juego de lotería con grandes premios acumulados.",
        howToPlay: [
          "Selecciona 6 números del 1 al 38.",
          "Marca tus selecciones en el boleto.",
          "Gana acertando 3, 4, 5 o los 6 números sorteados."
        ],
        prizes: [
          "Jackpot para quien acierte los 6 números.",
          "Premios menores por acertar 3, 4 o 5 números."
        ]
      },
      {
        title: "Super Kino TV",
        description: "Juego de lotería televisado con múltiples oportunidades de ganar.",
        howToPlay: [
          "Elige 10 números del 1 al 80.",
          "Decide cuántos sorteos quieres jugar.",
          "Observa el sorteo en vivo por televisión."
        ],
        prizes: [
          "Premios variables según la cantidad de números acertados.",
          "Bono adicional por acertar todos los números seleccionados."
        ]
      },
      {
        title: "Loto - Super Loto Más",
        description: "Lotería con grandes premios y jackpot acumulado.",
        howToPlay: [
          "Selecciona 6 números principales del 1 al 38.",
          "Elige 1 número adicional del 1 al 10 (Super Número).",
          "Marca si deseas participar en Loto, Super Loto o ambos."
        ],
        prizes: [
          "Jackpot millonario para quien acierte los 6 números más el Super Número.",
          "Múltiples categorías de premios según aciertos."
        ]
      },
      {
        title: "Super Pale",
        description: "Juego que combina dos quinielas en una apuesta.",
        howToPlay: [
          "Elige dos números de 2 cifras cada uno.",
          "Decide el orden de los números (directo o combinado).",
          "Realiza tu apuesta."
        ],
        prizes: [
          "Premio mayor por acertar ambos números en el orden correcto.",
          "Premio menor por acertar en orden combinado."
        ]
      }
    ];

    return (
      <Box mt={6}>
        <Typography variant="h4" gutterBottom sx={{ color: theme.palette.primary.main, textAlign: 'center', mb: 4 }}>
          Cómo Jugar
        </Typography>
        <Grid container spacing={4}>
          {gameInfo.map((game, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <InfoPaper elevation={3}>
                <GameTitle variant="h5">
                  <CasinoIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                  {game.title}
                </GameTitle>
                <Typography variant="body2" paragraph>
                  {game.description}
                </Typography>
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.secondary.main }}>
                  ¿Cómo jugar?
                </Typography>
                <List dense>
                  {game.howToPlay.map((step, idx) => (
                    <ListItem key={idx}>
                      <ListItemIcon>
                        {idx === 0 ? <LooksOneIcon color="primary" /> :
                         idx === 1 ? <LooksTwoIcon color="primary" /> :
                         idx === 2 ? <Looks3Icon color="primary" /> :
                         idx === 3 ? <Looks4Icon color="primary" /> :
                         idx === 4 ? <Looks5Icon color="primary" /> :
                         <Looks6Icon color="primary" />}
                      </ListItemIcon>
                      <ListItemText primary={step} />
                    </ListItem>
                  ))}
                </List>
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.secondary.main, mt: 2 }}>
                  Premios:
                </Typography>
                <List dense>
                  {game.prizes.map((prize, idx) => (
                    <ListItem key={idx}>
                      <ListItemIcon>
                        <EmojiEventsIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText primary={prize} />
                    </ListItem>
                  ))}
                </List>
              </InfoPaper>
            </Grid>
          ))}
        </Grid>
        <Box mt={4}>
          <InfoPaper elevation={3}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
              <InfoIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
              Consejos para Jugar Responsablemente
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <AttachMoneyIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Establece un presupuesto" 
                  secondary="Decide cuánto puedes gastar en lotería y no excedas ese límite."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LooksOneIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Juega con moderación" 
                  secondary="La lotería debe ser una forma de entretenimiento, no una fuente de ingresos."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <EmojiEventsIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Conoce las probabilidades" 
                  secondary="Entiende las posibilidades reales de ganar en cada juego de Leidsa."
                />
              </ListItem>
            </List>
          </InfoPaper>
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ flex: 1, width: '100%', maxWidth: '1200px', margin: '0 auto', p: { xs: 2, sm: 3 } }}>
      <Typography 
        variant="h4" 
        gutterBottom 
        sx={{ 
          mb: 4, 
          fontWeight: 'bold', 
          color: '#4CAF50', 
          textAlign: 'center', 
          fontSize: { xs: '1.5rem', sm: '2rem' },
          textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
        }}
      >
        Resultados de Leidsa
      </Typography>
      {error ? (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      ) : (
        <Grid container spacing={3} justifyContent="center">
          {renderLotteryCard('Pega 3 Más', results.pega3Mas)}
          {renderLotteryCard('Quiniela Leidsa', results.quiniela)}
          {renderLotteryCard('Loto Pool', results.lotoPool)}
          {renderLotteryCard('Super Kino TV', results.superKino)}
          {renderLotteryCard('Loto - Super Loto Más', results.lotoSuperLotoMas)}
          {renderLotteryCard('Super Pale', results.superPale)}
        </Grid>
      )}
      <Divider sx={{ my: 6 }} />
      {renderHowToPlay()}
    </Box>
  );
};

export default Leidsa;