import React, { useState, useEffect } from 'react';
import { Typography, Alert, Grid, Card, CardContent, Box, Skeleton, Container, Divider, Paper, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import EventIcon from '@mui/icons-material/Event';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import Brightness3Icon from '@mui/icons-material/Brightness3';
import WbTwilightIcon from '@mui/icons-material/WbTwilight';
import CasinoIcon from '@mui/icons-material/Casino';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import InfoIcon from '@mui/icons-material/Info';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {
  getPick3Dia,
  getPick3Noche,
  getPhilipsburgDia,
  getPhilipsburgNoche,
  getPick4Noche,
  getLotoPoolNoche,
  getPick4Dia,
  getLotoPoolMedioDia,
  getKingLotteryNoche,
  getKingLotteryDia
} from '../services/api';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease-in-out',
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  background: 'linear-gradient(145deg, #ffffff, #f0f0f0)',
  boxShadow: '2px 2px 5px #d1d1d1, -2px -2px 5px #ffffff', // Reduced shadow intensity
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '4px 4px 10px #c1c1c1, -4px -4px 10px #ffffff', // Reduced hover shadow intensity
  },
}));

const CardHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  background: 'linear-gradient(45deg, #FFD700, #FFA500)',
  color: theme.palette.common.white,
  textAlign: 'center',
}));

const ResultNumber = styled(Box)(({ theme, count }) => ({
  margin: theme.spacing(0.5),
  fontSize: count > 2 ? '1.2rem' : '1.5rem',
  fontWeight: 'bold',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  height: count > 2 ? '60px' : '48px',
  width: count > 2 ? '60px' : '48px',
  borderRadius: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: count > 2 ? theme.spacing(1) : 0,
}));

const DateChip = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: '#f0f0f0',
  border: '1px dashed #FFD700',
  color: theme.palette.text.primary,
  padding: theme.spacing(0.5, 1),
  borderRadius: '16px',
  display: 'inline-flex',
  alignItems: 'center',
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(0.5),
  },
}));

const InfoPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.spacing(2),
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[10],
  },
}));

const GameTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
}));

const KingLottery = () => {
  const [results, setResults] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const [
          pick3Dia,
          pick3Noche,
          philipsburgDia,
          philipsburgNoche,
          pick4Noche,
          lotoPoolNoche,
          pick4Dia,
          lotoPoolMedioDia,
          kingLotteryNoche,
          kingLotteryDia
        ] = await Promise.all([
          getPick3Dia(),
          getPick3Noche(),
          getPhilipsburgDia(),
          getPhilipsburgNoche(),
          getPick4Noche(),
          getLotoPoolNoche(),
          getPick4Dia(),
          getLotoPoolMedioDia(),
          getKingLotteryNoche(),
          getKingLotteryDia()
        ]);

        setResults({
          'Pick 3 Día': pick3Dia,
          'Pick 3 Noche': pick3Noche,
          'Philipsburg Medio Día': philipsburgDia,
          'Philipsburg Noche': philipsburgNoche,
          'Pick 4 Noche': pick4Noche,
          'Loto Pool Noche': lotoPoolNoche,
          'Pick 4 Día': pick4Dia,
          'Loto Pool Medio Día': lotoPoolMedioDia,
          'King Lottery Noche': kingLotteryNoche,
          'King Lottery Día': kingLotteryDia
        });
      } catch (error) {
        setError('Error al obtener los resultados de King Lottery. Intenta nuevamente más tarde.');
        console.error('Error fetching King Lottery results:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, []);

  const renderLotteryCard = (title, data, icon) => (
    <Grid item xs={12} sm={6} md={4}>
      <StyledCard>
        <CardHeader>
          <Typography variant="h6" component="h2" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {icon}
            {title}
          </Typography>
        </CardHeader>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
          {loading ? (
            <>
              <Skeleton variant="text" width="60%" height={24} />
              <Skeleton variant="text" width="40%" height={24} />
              <Box display="flex" flexWrap="wrap" justifyContent="center" mt={2}>
                {[...Array(5)].map((_, idx) => (
                  <Skeleton key={idx} variant="rectangular" width={48} height={48} style={{ margin: 4, borderRadius: 8 }} />
                ))}
              </Box>
            </>
          ) : !data || data.length === 0 ? (
            <Typography variant="body2">No hay resultados disponibles.</Typography>
          ) : (
            data.map((result, index) => (
              <Box key={index} mb={3} sx={{ textAlign: 'center', width: '100%' }}>
                <Typography variant="subtitle1" color="primary" gutterBottom sx={{ fontWeight: 'bold' }}>
                  {result.name}
                </Typography>
                <Box display="flex" flexWrap="wrap" justifyContent="center" mb={2}>
                  {result.numbers.map((number, idx) => (
                    <ResultNumber 
                      key={idx} 
                      count={result.numbers.length}
                    >
                      {number}
                    </ResultNumber>
                  ))}
                </Box>
                <DateChip>
                  <EventIcon fontSize="small" />
                  <Typography variant="body2">{result.date}</Typography>
                </DateChip>
              </Box>
            ))
          )}
        </CardContent>
      </StyledCard>
    </Grid>
  );

  const renderHowToPlay = () => {
    const gameInfo = [
      {
        title: "Pick 3",
        description: "Juego de lotería donde debes acertar 3 números.",
        howToPlay: [
          "Elige 3 números del 0 al 9.",
          "Decide el tipo de apuesta: directa, combinada, o en cualquier orden.",
          "Selecciona el monto de tu apuesta."
        ],
        prizes: [
          "Premio mayor por acertar los 3 números en el orden exacto.",
          "Premios menores por combinaciones parciales o en diferente orden."
        ]
      },
      {
        title: "Pick 4",
        description: "Similar al Pick 3, pero con 4 números.",
        howToPlay: [
          "Selecciona 4 números del 0 al 9.",
          "Escoge entre jugar en orden exacto o en cualquier orden.",
          "Determina el monto de tu apuesta."
        ],
        prizes: [
          "Gran premio por acertar los 4 números en el orden correcto.",
          "Premios adicionales por aciertos parciales o en orden diferente."
        ]
      },
      {
        title: "Loto Pool",
        description: "Juego de lotería con múltiples sorteos.",
        howToPlay: [
          "Elige tus números favoritos.",
          "Decide en cuántos sorteos quieres participar.",
          "Realiza tu apuesta."
        ],
        prizes: [
          "Diferentes categorías de premios según los aciertos.",
          "Posibilidad de ganar en múltiples sorteos con una sola apuesta."
        ]
      },
      {
        title: "Philipsburg (Medio Día y Noche)",
        description: "Lotería de Sint Maarten con dos sorteos diarios.",
        howToPlay: [
          "Selecciona 3 números del 0 al 9 para el sorteo de medio día o noche.",
          "Elige entre apuesta directa (en orden) o en cualquier orden.",
          "Determina el monto de tu apuesta."
        ],
        prizes: [
          "Premio principal por acertar los 3 números en el orden exacto.",
          "Premios menores por aciertos parciales o en cualquier orden."
        ]
      },
      {
        title: "King Lottery (Día y Noche)",
        description: "Lotería principal de Sint Maarten con sorteos diarios.",
        howToPlay: [
          "Escoge 4 números del 00 al 99 para el sorteo diurno o nocturno.",
          "Decide si quieres jugar en orden exacto o en cualquier orden.",
          "Selecciona el monto que deseas apostar."
        ],
        prizes: [
          "Gran premio por acertar los 4 números en el orden correcto.",
          "Premios secundarios por combinaciones parciales o en diferente orden.",
          "Bono especial por acertar números consecutivos o pares/impares."
        ]
      }
    ];

    return (
      <Box mt={6}>
        <Typography variant="h4" gutterBottom sx={{ color: '#FFD700', textAlign: 'center', mb: 4 }}>
          Cómo Jugar
        </Typography>
        <Grid container spacing={4}>
          {gameInfo.map((game, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <InfoPaper elevation={3}>
                <GameTitle variant="h5">
                  <CasinoIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                  {game.title}
                </GameTitle>
                <Typography variant="body2" paragraph>
                  {game.description}
                </Typography>
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: '#FFA500' }}>
                  ¿Cómo jugar?
                </Typography>
                <List dense>
                  {game.howToPlay.map((step, idx) => (
                    <ListItem key={idx}>
                      <ListItemIcon>
                        {idx === 0 ? <LooksOneIcon color="primary" /> :
                         idx === 1 ? <LooksTwoIcon color="primary" /> :
                         <Looks3Icon color="primary" />}
                      </ListItemIcon>
                      <ListItemText primary={step} />
                    </ListItem>
                  ))}
                </List>
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: '#FFA500', mt: 2 }}>
                  Premios:
                </Typography>
                <List dense>
                  {game.prizes.map((prize, idx) => (
                    <ListItem key={idx}>
                      <ListItemIcon>
                        <EmojiEventsIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText primary={prize} />
                    </ListItem>
                  ))}
                </List>
              </InfoPaper>
            </Grid>
          ))}
        </Grid>
        <Box mt={4}>
          <InfoPaper elevation={3}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#FFD700' }}>
              <InfoIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
              Consejos para Jugar Responsablemente
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <AttachMoneyIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Establece un presupuesto" 
                  secondary="Decide cuánto puedes gastar en lotería y no excedas ese límite."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LooksOneIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Juega por diversión" 
                  secondary="Recuerda que la lotería es una forma de entretenimiento, no una estrategia financiera."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <EmojiEventsIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Conoce las probabilidades" 
                  secondary="Entiende las posibilidades reales de ganar en cada juego de King Lottery."
                />
              </ListItem>
            </List>
          </InfoPaper>
        </Box>
      </Box>
    );
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', p: 3 }}>
        <Typography 
          variant="h4" 
          gutterBottom 
          sx={{ 
            mb: 4, 
            fontWeight: 'bold', 
            color: '#FFD700', 
            textAlign: 'center', 
            fontSize: { xs: '1.5rem', sm: '2rem' },
            textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
          }}
        >
          Resultados de King Lottery
        </Typography>
        {error ? (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        ) : (
          <Grid container spacing={3} justifyContent="center">
            {renderLotteryCard('Pick 3 Día', results['Pick 3 Día'], <WbSunnyIcon sx={{ mr: 1 }} />)}
            {renderLotteryCard('Pick 3 Noche', results['Pick 3 Noche'], <Brightness3Icon sx={{ mr: 1 }} />)}
            {renderLotteryCard('Philipsburg Medio Día', results['Philipsburg Medio Día'], <WbTwilightIcon sx={{ mr: 1 }} />)}
            {renderLotteryCard('Philipsburg Noche', results['Philipsburg Noche'], <Brightness3Icon sx={{ mr: 1 }} />)}
            {renderLotteryCard('Pick 4 Noche', results['Pick 4 Noche'], <Brightness3Icon sx={{ mr: 1 }} />)}
            {renderLotteryCard('Loto Pool Noche', results['Loto Pool Noche'], <Brightness3Icon sx={{ mr: 1 }} />)}
            {renderLotteryCard('Pick 4 Día', results['Pick 4 Día'], <WbSunnyIcon sx={{ mr: 1 }} />)}
            {renderLotteryCard('Loto Pool Medio Día', results['Loto Pool Medio Día'], <WbTwilightIcon sx={{ mr: 1 }} />)}
            {renderLotteryCard('King Lottery Noche', results['King Lottery Noche'], <Brightness3Icon sx={{ mr: 1 }} />)}
            {renderLotteryCard('King Lottery Día', results['King Lottery Día'], <WbSunnyIcon sx={{ mr: 1 }} />)}
          </Grid>
        )}
        <Divider sx={{ my: 6 }} />
        {renderHowToPlay()}
      </Box>
    </Container>
  );
};

export default KingLottery;