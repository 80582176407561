import React, { useEffect, useState, useCallback } from 'react';
import { fetchTotalVisits, fetchOnlineVisitors, registerVisit, updateActivity } from '../services/visitapi';
import { Card, CardContent, Typography, CircularProgress, Container, Box, Alert, useTheme, useMediaQuery, Fade, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PeopleAlt, Visibility, Update } from '@mui/icons-material';

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  background: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
  boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 15px 30px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 60,
  height: 60,
  borderRadius: '50%',
  backgroundColor: 'rgba(255, 255, 255, 0.2)',
  marginBottom: theme.spacing(2),
}));

const StatValue = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
  color: '#ffffff',
}));

const StatLabel = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: 'rgba(255, 255, 255, 0.8)',
}));

const UpdateInfo = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  color: 'rgba(255, 255, 255, 0.6)',
  marginTop: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Visit = () => {
  const [totalVisits, setTotalVisits] = useState(null);
  const [onlineVisitors, setOnlineVisitors] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchData = useCallback(async () => {
    try {
      const [visits, visitors] = await Promise.all([fetchTotalVisits(), fetchOnlineVisitors()]);
      setTotalVisits(visits);
      setOnlineVisitors(visitors);
      setLastUpdate(new Date());
      setLoading(false);
    } catch (err) {
      console.error('Error al obtener los datos:', err);
      setError('Hubo un problema al obtener las estadísticas.');
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const registerAndFetch = async () => {
      await registerVisit();
      await fetchData();
    };

    registerAndFetch();

    const interval = setInterval(async () => {
      try {
        await updateActivity();
        await fetchData();
      } catch (err) {
        console.error('Error al actualizar los datos:', err);
        setError('Hubo un problema al actualizar las estadísticas.');
      }
    }, 30000); // Actualizar cada 30 segundos

    return () => clearInterval(interval);
  }, [fetchData]);

  const renderStatCard = (icon, value, label) => (
    <StyledCard>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 3 }}>
        <IconWrapper>
          {icon}
        </IconWrapper>
        <Fade in={!loading} timeout={1000}>
          <StatValue>
            {loading ? (
              <CircularProgress size={30} color="inherit" />
            ) : (
              value?.toLocaleString()
            )}
          </StatValue>
        </Fade>
        <StatLabel>{label}</StatLabel>
      </CardContent>
    </StyledCard>
  );

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, mb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h3" component="h1" gutterBottom sx={{ mb: 4, fontWeight: 'bold', color: theme.palette.primary.main }}>
          Estadísticas de la Página
        </Typography>

        {error ? (
          <Alert severity="error" sx={{ width: '100%', mb: 2 }}>{error}</Alert>
        ) : (
          <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 3, width: '100%' }}>
            {renderStatCard(
              <Visibility sx={{ fontSize: 30, color: 'white' }} />,
              totalVisits,
              'Total de visitas'
            )}
            {renderStatCard(
              <PeopleAlt sx={{ fontSize: 30, color: 'white' }} />,
              onlineVisitors,
              'Visitantes en línea'
            )}
          </Box>
        )}

        <Tooltip title="Última actualización" arrow>
          <UpdateInfo>
            <Update sx={{ fontSize: 16, marginRight: 0.5 }} />
            {lastUpdate ? `Actualizado: ${lastUpdate.toLocaleTimeString('es-ES')}` : 'Actualizando...'}
          </UpdateInfo>
        </Tooltip>
      </Box>
    </Container>
  );
};

export default Visit;