import React, { useState, useEffect } from 'react';
import { Typography, Alert, Grid, Card, CardContent, Box, Skeleton, useMediaQuery, useTheme, Divider, Paper, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getReal, getPega4Real, getLotoPoolReal, getNYReal, getLotoReal, getSuperPaleReal, getQuinielita } from '../services/api';
import CasinoIcon from '@mui/icons-material/Casino';
import EventIcon from '@mui/icons-material/Event';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import Looks6Icon from '@mui/icons-material/Looks6';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import InfoIcon from '@mui/icons-material/Info';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease-in-out',
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  background: 'linear-gradient(145deg, #ffffff, #f0f0f0)',
  boxShadow: '2px 2px 5px #d1d1d1, -2px -2px 5px #ffffff', // Reduced shadow intensity
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '4px 4px 10px #c1c1c1, -4px -4px 10px #ffffff', // Reduced hover shadow intensity
  },
}));

const CardHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  background: 'linear-gradient(45deg, #FFA500, #FF6347)',
  color: theme.palette.common.white,
  textAlign: 'center',
}));

const ResultNumber = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40px',
  height: '40px',
  margin: theme.spacing(0.5),
  fontSize: '1.2rem',
  fontWeight: 'bold',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'scale(1.1)',
    backgroundColor: theme.palette.primary.dark,
  },
}));

const DateChip = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  padding: theme.spacing(0.5, 1),
  marginTop: theme.spacing(2),
  backgroundColor: '#f0f0f0',
  border: '1px dashed #FFA500',
  borderRadius: '16px',
  color: theme.palette.text.primary,
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(0.5),
  },
}));

const InfoPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.spacing(2),
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[10],
  },
}));

const GameTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
}));

const LoteriaReal = () => {
  const [results, setResults] = useState({
    real: [],
    pega4Real: [],
    lotoPoolReal: [],
    nyReal: [],
    lotoReal: [],
    superPaleReal: [],
    quinielita: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const [real, pega4Real, lotoPoolReal, nyReal, lotoReal, superPaleReal, quinielita] = await Promise.all([
          getReal(),
          getPega4Real(),
          getLotoPoolReal(),
          getNYReal(),
          getLotoReal(),
          getSuperPaleReal(),
          getQuinielita(),
        ]);
        setResults({ real, pega4Real, lotoPoolReal, nyReal, lotoReal, superPaleReal, quinielita });
      } catch (error) {
        setError('Error al obtener los resultados de las loterías. Intenta nuevamente más tarde.');
        console.error('Error fetching results:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, []);

  const renderLotteryCard = (title, data) => (
    <Grid item xs={12} sm={6} lg={4}>
      <StyledCard>
        <CardHeader>
          <Typography variant="h6" component="h2" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CasinoIcon sx={{ mr: 1 }} />
            {title}
          </Typography>
        </CardHeader>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
          {loading ? (
            <>
              <Skeleton variant="text" width="60%" height={24} />
              <Skeleton variant="text" width="40%" height={24} />
              <Box display="flex" flexWrap="wrap" justifyContent="center" mt={2}>
                {[...Array(5)].map((_, idx) => (
                  <Skeleton key={idx} variant="rectangular" width={40} height={40} style={{ margin: 4, borderRadius: '8px' }} />
                ))}
              </Box>
            </>
          ) : data.length === 0 ? (
            <Typography variant="body2">No hay resultados disponibles.</Typography>
          ) : (
            data.map((result, index) => (
              <Box key={index} mb={3} sx={{ textAlign: 'center', width: '100%' }}>
                <Typography variant="subtitle1" color="primary" gutterBottom sx={{ fontWeight: 'bold' }}>
                  {result.name}
                </Typography>
                <Box display="flex" flexWrap="wrap" justifyContent="center" mb={2}>
                  {result.numbers.map((number, idx) => (
                    <ResultNumber key={idx} sx={{ fontSize: isSmallScreen ? '1rem' : '1.2rem' }}>
                      {number}
                    </ResultNumber>
                  ))}
                </Box>
                <DateChip>
                  <EventIcon fontSize="small" />
                  <Typography variant="caption">{result.date}</Typography>
                </DateChip>
              </Box>
            ))
          )}
        </CardContent>
      </StyledCard>
    </Grid>
  );

  const renderHowToPlay = () => {
    const gameInfo = [
      {
        title: "Lotería Real",
        description: "El juego clásico de la Lotería Real con grandes premios.",
        howToPlay: [
          "Elige un número de 2 cifras del 00 al 99.",
          "Realiza tu apuesta.",
          "Espera el sorteo para ver si tu número resulta ganador."
        ],
        prizes: [
          "Si tu número coincide exactamente con el número sorteado, ganarás un premio basado en la cantidad apostada."
        ]
      },
      {
        title: "Pega 4 Real",
        description: "Elige 4 números y gana grandes premios.",
        howToPlay: [
          "Selecciona 4 números del 0 al 9.",
          "Decide el monto de tu apuesta.",
          "Gana si aciertas los 4 números en el orden correcto."
        ],
        prizes: [
          "El premio varía según la cantidad apostada y la coincidencia exacta de los números sorteados."
        ]
      },
      {
        title: "Loto Pool Real",
        description: "Juego de lotería con acumulado y múltiples formas de ganar.",
        howToPlay: [
          "Elige 6 números del 1 al 38.",
          "Marca tus selecciones en el boleto.",
          "Gana acertando 3, 4, 5 o los 6 números sorteados."
        ],
        prizes: [
          "Premios por acertar 3, 4, 5 o 6 números.",
          "Jackpot acumulado para quien acierte los 6 números."
        ]
      },
      {
        title: "Nueva York Real",
        description: "Juega con los números de la lotería de Nueva York.",
        howToPlay: [
          "Selecciona los números según las reglas del sorteo de Nueva York.",
          "Realiza tu apuesta.",
          "Espera los resultados oficiales para ver si has ganado."
        ],
        prizes: [
          "Los premios varían según el tipo de apuesta y los números acertados."
        ]
      },
      {
        title: "Loto Real",
        description: "Lotería con jackpot acumulado y múltiples categorías de premios.",
        howToPlay: [
          "Elige 6 números principales del 1 al 38.",
          "Selecciona 1 número adicional del 1 al 10.",
          "Gana acertando diferentes combinaciones de números."
        ],
        prizes: [
          "Múltiples categorías de premios según los números acertados.",
          "Gran jackpot para quien acierte todos los números."
        ]
      },
      {
        title: "Super Pale Real",
        description: "Combina dos loterías en una para mayores probabilidades de ganar.",
        howToPlay: [
          "Elige dos números de 2 cifras cada uno.",
          "Decide el monto de tu apuesta.",
          "Gana si aciertas la combinación en el orden correcto."
        ],
        prizes: [
          "Premios basados en la coincidencia de los números y el orden de los mismos."
        ]
      },
      {
        title: "Quinielita",
        description: "Juego diario con resultados basados en la fecha actual.",
        howToPlay: [
          "No necesitas elegir números, se basa en la fecha del día.",
          "Realiza tu apuesta antes del cierre diario.",
          "Los números ganadores se generan automáticamente basados en la fecha."
        ],
        prizes: [
          "Premios diarios basados en la coincidencia con los números generados.",
          "Oportunidad de ganar todos los días del año."
        ]
      }
    ];

    return (
      <Box mt={6}>
        <Typography variant="h4" gutterBottom sx={{ color: theme.palette.primary.main, textAlign: 'center', mb: 4 }}>
          Cómo Jugar
        </Typography>
        <Grid container spacing={4}>
          {gameInfo.map((game, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <InfoPaper elevation={3}>
                <GameTitle variant="h5">
                  <CasinoIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                  {game.title}
                </GameTitle>
                <Typography variant="body2" paragraph>
                  {game.description}
                </Typography>
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.secondary.main }}>
                  ¿Cómo jugar?
                </Typography>
                <List dense>
                  {game.howToPlay.map((step, idx) => (
                    <ListItem key={idx}>
                      <ListItemIcon>
                        {idx === 0 ? <LooksOneIcon color="primary" /> :
                         idx === 1 ? <LooksTwoIcon color="primary" /> :
                         idx === 2 ? <Looks3Icon color="primary" /> :
                         idx === 3 ? <Looks4Icon color="primary" /> :
                         idx === 4 ? <Looks5Icon color="primary" /> :
                         <Looks6Icon color="primary" />}
                      </ListItemIcon>
                      <ListItemText primary={step} />
                    </ListItem>
                  ))}
                </List>
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.secondary.main, mt: 2 }}>
                  Premios:
                </Typography>
                <List dense>
                  {game.prizes.map((prize, idx) => (
                    <ListItem key={idx}>
                      <ListItemIcon>
                        <EmojiEventsIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText primary={prize} />
                    </ListItem>
                  ))}
                </List>
              </InfoPaper>
            </Grid>
          ))}
        </Grid>
        <Box mt={4}>
          <InfoPaper elevation={3}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
              <InfoIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
              Consejos para Jugar Responsablemente
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <AttachMoneyIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Define tu presupuesto" 
                  secondary="Jugar responsablemente es importante. Establece una cantidad fija para apostar que no afecte tus finanzas."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LooksOneIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Elige tus números con calma" 
                  secondary="Puedes elegir números de manera aleatoria o usar fechas especiales o números favoritos."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <EmojiEventsIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Revisa los resultados" 
                  secondary="Después de cada sorteo, verifica si tus números fueron ganadores."
                />
              </ListItem>
            </List>
          </InfoPaper>
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ flexGrow: 1, width: '100%', maxWidth: '1200px', margin: '0 auto', p: { xs: 2, sm: 3 } }}>
      <Typography 
        variant="h4" 
        gutterBottom 
        sx={{ 
          mb: 4, 
          fontWeight: 'bold', 
          color: '#FF6347', 
          textAlign: 'center', 
          fontSize: { xs: '1.5rem', sm: '2rem' },
          textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
        }}
      >
        Resultados de Lotería Real
      </Typography>
      {error ? (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      ) : (
        <Grid container spacing={3} justifyContent="center">
          {renderLotteryCard('Lotería Real', results.real)}
          {renderLotteryCard('Pega 4 Real', results.pega4Real)}
          {renderLotteryCard('Loto Pool Real', results.lotoPoolReal)}
          {renderLotteryCard('Nueva York Real', results.nyReal)}
          {renderLotteryCard('Loto Real', results.lotoReal)}
          {renderLotteryCard('Super Pale Real', results.superPaleReal)}
          {renderLotteryCard('Quinielita', results.quinielita)}
        </Grid>
      )}
      <Divider sx={{ my: 6 }} />
      {renderHowToPlay()}
    </Box>
  );
};

export default LoteriaReal;