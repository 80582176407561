import React from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  Container, 
  Link as MuiLink,
  useTheme
} from '@mui/material';
import { Home as HomeIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';

export default function Cosmic404() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: '70vh',
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          position: 'relative',
          zIndex: 1,
        }}
      >
        <Typography
          variant="h1"
          component="h1"
          sx={{
            fontSize: { xs: '6rem', sm: '9rem' },
            fontWeight: 700,
            color: 'common.white',
            mb: 2,
          }}
        >
          404
        </Typography>
        <Typography
          variant="h4"
          component="p"
          sx={{
            color: 'common.white',
            mb: 4,
          }}
        >
          ¡Ups! Has entrado en el vacío cósmico. La página que buscas no existe.
        </Typography>
        <Button
          component={Link}
          to="/"
          variant="contained"
          startIcon={<HomeIcon />}
          size="large"
          sx={{
            backgroundColor: '#0e3d6d',
            color: theme.palette.primary.contrastText,
            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
            },
            transition: 'all 0.3s ease-in-out',
            px: 4,
            py: 1.5,
            borderRadius: '30px',
            fontSize: '1.2rem',
          }}
        >
          Regresar al inicio
        </Button>
      </Container>
      <Box
        sx={{
          position: 'absolute',
          bottom: 16,
          left: 16,
        }}
      >
        <MuiLink
          component={Link}
          to="/sitemap"
          sx={{
            color: 'common.white',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >

        </MuiLink>
      </Box>
    </Box>
  );
}