import React, { useState, useEffect } from 'react';
import { Typography, Alert, Grid, Card, CardContent, Box, Skeleton, Container, Divider, Paper, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getFloridaDia, getNewYorkDia, getNewYorkNoche, getFloridaNoche, getCash4life, getMegaMillions, getPowerball } from '../services/api';
import CasinoIcon from '@mui/icons-material/Casino';
import EventIcon from '@mui/icons-material/Event';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import Brightness3Icon from '@mui/icons-material/Brightness3';
import PublicIcon from '@mui/icons-material/Public';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import InfoIcon from '@mui/icons-material/Info';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease-in-out',
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  background: 'linear-gradient(145deg, #ffffff, #f0f0f0)',
  boxShadow: '2px 2px 5px #d1d1d1, -2px -2px 5px #ffffff',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '4px 4px 10px #c1c1c1, -4px -4px 10px #ffffff',
  },
}));

const CardHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  background: 'linear-gradient(45deg, #1E88E5, #1565C0)',
  color: theme.palette.common.white,
  textAlign: 'center',
}));

const ResultNumber = styled(Box)(({ theme, count }) => ({
  margin: theme.spacing(0.5),
  fontSize: count > 2 ? '1.2rem' : '1.5rem',
  fontWeight: 'bold',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  height: count > 2 ? '60px' : '48px',
  width: count > 2 ? '60px' : '48px',
  borderRadius: count > 2 ? '8px' : '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: count > 2 ? theme.spacing(1) : 0,
}));

const DateChip = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: '#f0f0f0',
  border: '1px dashed #1E88E5',
  color: theme.palette.text.primary,
  padding: theme.spacing(0.5, 1),
  borderRadius: '16px',
  display: 'inline-flex',
  alignItems: 'center',
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(0.5),
  },
}));

const InfoPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.spacing(2),
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[10],
  },
}));

const GameTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
}));

const JackpotAmount = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.secondary.main,
  marginTop: theme.spacing(1),
}));

const Americanas = () => {
  const [results, setResults] = useState({
    floridaDia: [],
    newYorkTarde: [],
    newYorkNoche: [],
    cash4Life: [],
    megaMillions: [],
    floridaNoche: [],
    powerball: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const [floridaDia, newYorkDia, newYorkNoche, floridaNoche, cash4Life, megaMillions, powerball] = await Promise.all([
          getFloridaDia(),
          getNewYorkDia(),
          getNewYorkNoche(),
          getFloridaNoche(),
          getCash4life(),
          getMegaMillions(),
          getPowerball(),
        ]);
        setResults({
          floridaDia,
          newYorkTarde: newYorkDia,
          newYorkNoche,
          cash4Life,
          megaMillions,
          floridaNoche,
          powerball,
        });
      } catch (error) {
        setError('Error al obtener los resultados de las loterías Americanas. Intenta nuevamente más tarde.');
        console.error('Error fetching Americanas results:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, []);

  const renderLotteryCard = (title, data, icon) => (
    <Grid item xs={12} sm={6} md={4}>
      <StyledCard>
        <CardHeader>
          <Typography variant="h6" component="h2" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {icon}
            {title}
          </Typography>
        </CardHeader>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
          {loading ? (
            <>
              <Skeleton variant="text" width="60%" height={24} />
              <Skeleton variant="text" width="40%" height={24} />
              <Box display="flex" flexWrap="wrap" justifyContent="center" mt={2}>
                {[...Array(5)].map((_, idx) => (
                  <Skeleton key={idx} variant="circular" width={48} height={48} style={{ margin: 4 }} />
                ))}
              </Box>
            </>
          ) : data.length === 0 ? (
            <Typography variant="body2">No hay resultados disponibles.</Typography>
          ) : (
            data.map((result, index) => (
              <Box key={index} mb={3} sx={{ textAlign: 'center', width: '100%' }}>
                <Typography variant="subtitle1" color="primary" gutterBottom sx={{ fontWeight: 'bold' }}>
                  {result.name}
                </Typography>
                <Box display="flex" flexWrap="wrap" justifyContent="center" mb={2}>
                  {result.numbers.map((number, idx) => (
                    <ResultNumber 
                      key={idx}
                      count={result.numbers.length}
                    >
                      {number}
                    </ResultNumber>
                  ))}
                </Box>
                {(title === 'Mega Millions' || title === 'Powerball') && result.jackpot && (
                  <JackpotAmount variant="h6">
                    Acumulado: ${result.jackpot.toLocaleString()}
                  </JackpotAmount>
                )}
                <DateChip>
                  <EventIcon fontSize="small" />
                  <Typography variant="body2">{result.date}</Typography>
                </DateChip>
              </Box>
            ))
          )}
        </CardContent>
      </StyledCard>
    </Grid>
  );

  const renderHowToPlay = () => {
    const gameInfo = [
      {
        title: "Florida Día/Noche",
        description: "Lotería de Florida con sorteos diarios.",
        howToPlay: [
          "Elige 3 o 4 números del 0 al 9.",
          "Decide el tipo de apuesta: exacta, combinada, o en cualquier orden.",
          "Selecciona el monto de tu apuesta."
        ],
        prizes: [
          "Premio mayor por acertar los números en el orden exacto.",
          "Premios menores por combinaciones parciales."
        ]
      },
      {
        title: "New York Tarde/Noche",
        description: "Lotería de Nueva York con sorteos diarios.",
        howToPlay: [
          "Selecciona 3 números del 0 al 9 para el Pick 3.",
          "O elige 4 números del 0 al 9 para el Pick 4.",
          "Escoge entre jugar en orden exacto o en cualquier orden."
        ],
        prizes: [
          "Gana el premio mayor acertando los números en el orden correcto.",
          "Premios menores por aciertos parciales o en diferente orden."
        ]
      },
      {
        title: "Cash4Life",
        description: "Lotería con premios de por vida.",
        howToPlay: [
          "Selecciona 5 números del 1 al 60.",
          "Elige 1 número Cash Ball del 1 al 4.",
          "Cada jugada cuesta $2."
        ],
        prizes: [
          "$1,000 diarios de por vida por acertar los 5 números + Cash Ball.",
          "$1,000 semanales de por vida por acertar los 5 números sin Cash Ball.",
          "Otros premios menores por combinaciones parciales."
        ]
      },
      {
        title: "Mega Millions",
        description: "Lotería multiestatal con grandes jackpots.",
        howToPlay: [
          "Elige 5 números del 1 al 70.",
          "Selecciona 1 Mega Ball del 1 al 25.",
          "Opcionalmente, añade Megaplier para multiplicar premios no jackpot."
        ],
        prizes: [
          "Jackpot millonario por acertar los 5 números + Mega Ball.",
          "Múltiples categorías de premios menores."
        ]
      },
      {
        title: "Powerball",
        description: "Una de las loterías más populares de EE.UU.",
        howToPlay: [
          "Selecciona 5 números del 1 al 69.",
          "Elige 1 Powerball del 1 al 26.",
          "Opción de añadir Power Play para multiplicar premios no jackpot."
        ],
        prizes: [
          "Jackpot multimillonario por acertar los 5 números + Powerball.",
          "Diversos premios menores por combinaciones parciales."
        ]
      }
    ];

    return (
      <Box mt={6}>
        <Typography variant="h4" gutterBottom sx={{ color: '#1E88E5', textAlign: 'center', mb: 4 }}>
          Cómo Jugar
        </Typography>
        <Grid container spacing={4}>
          {gameInfo.map((game, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <InfoPaper elevation={3}>
                <GameTitle variant="h5">
                  <CasinoIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                  {game.title}
                </GameTitle>
                <Typography variant="body2" paragraph>
                  {game.description}
                </Typography>
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: '#1565C0' }}>
                  ¿Cómo jugar?
                </Typography>
                <List dense>
                  {game.howToPlay.map((step, idx) => (
                    <ListItem key={idx}>
                      <ListItemIcon>
                        {idx === 0 ? <LooksOneIcon color="primary" /> :
                         idx === 1 ? <LooksTwoIcon color="primary" /> :
                         idx === 2 ? <Looks3Icon color="primary" /> :
                         idx === 3 ? <Looks4Icon color="primary" /> :
                         <Looks5Icon color="primary" />}
                      </ListItemIcon>
                      <ListItemText primary={step} />
                    </ListItem>
                  ))}
                </List>
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: '#1565C0', mt: 2 }}>
                  Premios:
                </Typography>
                <List dense>
                  {game.prizes.map((prize, idx) => (
                    <ListItem key={idx}>
                      <ListItemIcon>
                        <EmojiEventsIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText primary={prize} />
                    </ListItem>
                  ))}
                </List>
              </InfoPaper>
            </Grid>
          ))}
        </Grid>
        <Box mt={4}>
          <InfoPaper elevation={3}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#1E88E5' }}>
              <InfoIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
              Consejos para Jugar Responsablemente
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <AttachMoneyIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Establece un presupuesto" 
                  secondary="Decide cuánto puedes gastar en lotería y no excedas ese límite."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LooksOneIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Juega por diversión" 
                  secondary="La lotería debe ser una forma de entretenimiento, no una estrategia financiera."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <EmojiEventsIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Conoce las probabilidades" 
                  secondary="Entiende las posibilidades reales de ganar en cada juego de lotería."
                />
              </ListItem>
            </List>
          </InfoPaper>
        </Box>
      </Box>
    );
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', p: 3 }}>
        <Typography 
          variant="h4" 
          gutterBottom 
          sx={{ 
            mb: 4, 
            fontWeight: 'bold', 
            color: '#1E88E5', 
            textAlign: 'center', 
            fontSize: { xs: '1.5rem', sm: '2rem' },
            textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
          }}
        >
          Resultados de Loterías Americanas
        </Typography>
        {error ? (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        ) : (
          <Grid container spacing={3} justifyContent="center">
            {renderLotteryCard('Florida Día', results.floridaDia, <WbSunnyIcon sx={{ mr: 1 }} />)}
            {renderLotteryCard('New York Tarde', results.newYorkTarde, <WbSunnyIcon sx={{ mr: 1 }} />)}
            {renderLotteryCard('New York Noche', results.newYorkNoche, <Brightness3Icon sx={{ mr: 1 }} />)}
            {renderLotteryCard('Cash 4 Life', results.cash4Life, <CasinoIcon sx={{ mr: 1 }} />)}
            {renderLotteryCard('Mega Millions', results.megaMillions, <PublicIcon sx={{ mr: 1 }} />)}
            {renderLotteryCard('Florida Noche', results.floridaNoche, <Brightness3Icon sx={{ mr: 1 }} />)}
            {renderLotteryCard('Powerball', results.powerball, <PublicIcon sx={{ mr: 1 }} />)}
          </Grid>
        )}
        <Divider sx={{ my: 6 }} />
        {renderHowToPlay()}
      </Box>
    </Container>
  );
};

export default Americanas;