import React, { useState, useEffect } from 'react';
import { Typography, Alert, Grid, Card, CardContent, Chip, Box, Skeleton, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getLoteDom, getAgarra4Lotedom, getQuemaito, getSuperPaleLotedom } from '../services/api';
import CasinoIcon from '@mui/icons-material/Casino';
import EventIcon from '@mui/icons-material/Event';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease-in-out',
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  background: 'linear-gradient(145deg, #ffffff, #f0f0f0)',
  boxShadow: '2px 2px 5px #d1d1d1, -2px -2px 5px #ffffff', // Reduced shadow intensity
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '4px 4px 10px #c1c1c1, -4px -4px 10px #ffffff', // Reduced hover shadow intensity
  },
}));

const CardHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  background: 'linear-gradient(45deg, #3f51b5, #2196f3)',
  color: theme.palette.common.white,
  textAlign: 'center',
}));

const ResultNumber = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  fontSize: '1.2rem',
  fontWeight: 'bold',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const DateChip = styled(Chip)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: '#f0f0f0',
  border: '1px dashed #3f51b5',
  color: theme.palette.text.primary,
  '& .MuiChip-label': {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

const Lotedom = () => {
  const [results, setResults] = useState({
    quiniela: [],
    agarra4: [],
    quemaito: [],
    superPale: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const [quiniela, agarra4, quemaito, superPale] = await Promise.all([
          getLoteDom(),
          getAgarra4Lotedom(),
          getQuemaito(),
          getSuperPaleLotedom(),
        ]);
        setResults({ quiniela, agarra4, quemaito, superPale });
      } catch (error) {
        setError('Error al obtener los resultados de Lotedom. Intenta nuevamente más tarde.');
        console.error('Error fetching Lotedom results:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, []);

  const renderLotteryCard = (title, data) => (
    <Grid item xs={12} sm={6} lg={3}>
      <StyledCard>
        <CardHeader>
          <Typography variant="h6" component="h2" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CasinoIcon sx={{ mr: 1 }} />
            {title}
          </Typography>
        </CardHeader>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
          {loading ? (
            <>
              <Skeleton variant="text" width="60%" height={24} />
              <Skeleton variant="text" width="40%" height={24} />
              <Box display="flex" flexWrap="wrap" justifyContent="center" mt={2}>
                {[...Array(5)].map((_, idx) => (
                  <Skeleton key={idx} variant="circular" width={40} height={40} style={{ margin: 4 }} />
                ))}
              </Box>
            </>
          ) : data.length === 0 ? (
            <Typography variant="body2">No hay resultados disponibles.</Typography>
          ) : (
            data.map((result, index) => (
              <Box key={index} mb={3} sx={{ textAlign: 'center', width: '100%' }}>
                <Typography variant="subtitle1" color="primary" gutterBottom sx={{ fontWeight: 'bold' }}>
                  {result.name}
                </Typography>
                <Box display="flex" flexWrap="wrap" justifyContent="center" mb={2}>
                  {result.numbers.map((number, idx) => (
                    <ResultNumber 
                      key={idx} 
                      label={number} 
                      size={isSmallScreen ? "small" : "medium"}
                    />
                  ))}
                </Box>
                <DateChip 
                  icon={<EventIcon />} 
                  label={result.date}
                />
              </Box>
            ))
          )}
        </CardContent>
      </StyledCard>
    </Grid>
  );

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', p: 3 }}>
      <Typography 
        variant="h4" 
        gutterBottom 
        sx={{ 
          mb: 4, 
          fontWeight: 'bold', 
          color: '#3f51b5', 
          textAlign: 'center', 
          fontSize: { xs: '1.5rem', sm: '2rem' },
          textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
        }}
      >
        Resultados de Lotedom
      </Typography>
      {error ? (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      ) : (
        <Grid container spacing={3} justifyContent="center">
          {renderLotteryCard('Quiniela Lotedom', results.quiniela)}
          {renderLotteryCard('Agarra 4', results.agarra4)}
          {renderLotteryCard('El Quemaito Mayor', results.quemaito)}
          {renderLotteryCard('Super Pale', results.superPale)}
        </Grid>
      )}
    </Box>
  );
};

export default Lotedom;