'use client'

import React, { useState, useEffect, useRef } from 'react';
import { 
  Button, 
  Grid, 
  Paper, 
  Typography, 
  Box,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Zoom,
  Fade,
  Tooltip,
  IconButton,
  useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  HistoryOutlined, 
  AccessTimeOutlined, 
  Casino, 
  Refresh, 
  Star,
  AttachMoney as MoneyIcon,
  Nature as CloverIcon,
  EmojiEvents as TrophyIcon,
  Whatshot as FireIcon,
  Bolt as LightningIcon,
  Diamond as DiamondIcon,
  EmojiSymbols as DartIcon,
  RocketLaunch as RocketIcon,
  Stars as ShootingStarIcon,
  CardGiftcard as GiftIcon,
  VpnKey as KeyIcon,
  WbSunny as RainbowIcon,
  WbCloudy as SunBehindCloudIcon,
  EmojiObjects as LightbulbIcon,
  TrendingUp as UpTrendIcon,
  MilitaryTech as MedalIcon
} from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#00C853' },
    secondary: { main: '#69F0AE' },
    background: {
      default: 'transparent',
      paper: 'rgba(0, 77, 64, 0.8)',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#B9F6CA',
    },
  },
  typography: {
    fontFamily: '"Orbitron", "Roboto", "Helvetica", "Arial", sans-serif',
    h4: { fontWeight: 700 },
    h3: { fontWeight: 700 },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 30,
          textTransform: 'none',
          fontWeight: 600,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          backdropFilter: 'blur(10px)',
        },
      },
    },
  },
});

const GENERATION_LIMIT = 2;
const COOLDOWN_MINUTES = 360;

const luckyIcons = [
  { icon: MoneyIcon, description: "Abundancia y éxito financiero" },
  { icon: CloverIcon, description: "Suerte y buen augurio" },
  { icon: Star, description: "Éxito y brillantez" },
  { icon: TrophyIcon, description: "Victoria y logros" },
  { icon: FireIcon, description: "Racha ganadora" },
  { icon: LightningIcon, description: "Energía y poder" },
  { icon: DiamondIcon, description: "Valor y rareza" },
  { icon: Casino, description: "Azar favorable" },
  { icon: DartIcon, description: "Precisión y acierto" },
  { icon: RocketIcon, description: "Progreso y grandes logros" },
  { icon: ShootingStarIcon, description: "Oportunidad única" },
  { icon: GiftIcon, description: "Recompensa inesperada" },
  { icon: KeyIcon, description: "Clave del éxito" },
  { icon: RainbowIcon, description: "Esperanza y positividad" },
  { icon: SunBehindCloudIcon, description: "Buena fortuna en camino" },
  { icon: LightbulbIcon, description: "Inspiración ganadora" },
  { icon: UpTrendIcon, description: "Crecimiento y progreso" },
  { icon: MedalIcon, description: "Reconocimiento y honor" },
];

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  background: 'linear-gradient(145deg, rgb(0 200 83 / 4%), rgb(0 77 64 / 4%))',
  borderRadius: '16px',
  transition: 'all 0.3s ease-in-out',
  position: 'relative',
  overflow: 'hidden',
  height: '100px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid rgba(105, 240, 174, 0.1)',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  backdropFilter: 'blur(5px)',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 0 15px rgba(105, 240, 174, 0.5)',
  },
}));

const CosmicItem = ({ item, isRolling, isMobile }) => {
  const [displayItem, setDisplayItem] = useState(item);
  
  useEffect(() => {
    if (isRolling) {
      const interval = setInterval(() => {
        if (typeof item === 'string') {
          setDisplayItem(Math.floor(Math.random() * 100).toString().padStart(2, '0'));
        } else {
          setDisplayItem(luckyIcons[Math.floor(Math.random() * luckyIcons.length)]);
        }
      }, 50);
      return () => clearInterval(interval);
    } else {
      setDisplayItem(item);
    }
  }, [isRolling, item]);

  if (typeof displayItem === 'string') {
    return (
      <Box sx={{ 
        width: isMobile ? '80px' : '100px', 
        height: isMobile ? '60px' : '80px', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        background: 'linear-gradient(180deg, rgba(0, 200, 83, 0.2) 0%, rgba(0, 200, 83, 0.5) 100%)',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(105, 240, 174, 0.5)',
        overflow: 'hidden',
      }}>
        <Typography
          variant={isMobile ? "h4" : "h3"}
          sx={{
            color: '#FFFFFF',
            textShadow: '0 0 5px rgba(105, 240, 174, 0.7)',
            transition: 'all 0.05s ease-in-out',
          }}
        >
          {displayItem}
        </Typography>
      </Box>
    );
  } else {
    const IconComponent = displayItem.icon;
    return (
      <Tooltip title={displayItem.description} arrow>
        <Box>
          <IconComponent
            sx={{ 
              fontSize: isMobile ? 40 : 60, 
              color: '#69F0AE',
              filter: 'drop-shadow(0 0 5px rgba(105, 240, 174, 0.7))'
            }}
          />
        </Box>
      </Tooltip>
    );
  }
};

export default function CosmicNumberGenerator() {
  const [cosmicItems, setCosmicItems] = useState(['--', '--', '--', '--']);
  const [history, setHistory] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [generationCount, setGenerationCount] = useState(0);
  const [nextAvailableTime, setNextAvailableTime] = useState(null);
  const [remainingTime, setRemainingTime] = useState(null);
  const [isRolling, setIsRolling] = useState(false);
  const generationTimeoutRef = useRef(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const storedCount = localStorage.getItem('generationCount');
    const storedHistory = localStorage.getItem('history');
    const storedNextAvailableTime = localStorage.getItem('nextAvailableTime');
    
    if (storedCount) setGenerationCount(parseInt(storedCount, 10));
    if (storedHistory) {
      try {
        setHistory(JSON.parse(storedHistory));
      } catch (error) {
        console.error("Error al cargar el historial desde localStorage", error);
      }
    }
    if (storedNextAvailableTime) {
      const nextTime = new Date(storedNextAvailableTime);
      if (nextTime > new Date()) {
        setNextAvailableTime(nextTime);
      } else {
        localStorage.removeItem('nextAvailableTime');
        localStorage.removeItem('generationCount');
        setGenerationCount(0);
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('generationCount', generationCount.toString());
    localStorage.setItem('history', JSON.stringify(history));
    if (nextAvailableTime) {
      localStorage.setItem('nextAvailableTime', nextAvailableTime.toISOString());
    } else {
      localStorage.removeItem('nextAvailableTime');
    }
  }, [generationCount, history, nextAvailableTime]);

  useEffect(() => {
    if (nextAvailableTime) {
      const interval = setInterval(() => {
        const now = new Date();
        const diff = nextAvailableTime.getTime() - now.getTime();

        if (diff <= 0) {
          clearInterval(interval);
          setRemainingTime(null);
          setGenerationCount(0);
          setNextAvailableTime(null);
          localStorage.removeItem('generationCount');
          localStorage.removeItem('nextAvailableTime');
        } else {
          setRemainingTime(diff);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [nextAvailableTime]);

  const generateCosmicItems = () => {
    if (generationCount >= GENERATION_LIMIT) return;

    setIsGenerating(true);
    setIsRolling(true);
    setCosmicItems(['--', '--', '--', '--']);
    
    generationTimeoutRef.current = setTimeout(() => {
      const numbers = new Set();
      while (numbers.size < 2) {
        numbers.add(Math.floor(Math.random() * 100).toString().padStart(2, '0'));
      }
      const icons = new Set();
      while (icons.size < 2) {
        icons.add(luckyIcons[Math.floor(Math.random() * luckyIcons.length)]);
      }
      const newItems = [...numbers, ...icons];
      shuffleArray(newItems);
      setCosmicItems(newItems);
      finishGeneration(newItems);
    }, 1500);
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  };

  const finishGeneration = (newItems) => {
    setIsGenerating(false);
    setTimeout(() => setIsRolling(false), 200);

    setHistory(prevHistory => {
      const updatedHistory = [newItems, ...prevHistory];
      localStorage.setItem('history', JSON.stringify(updatedHistory));
      return updatedHistory;
    });

    setGenerationCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount >= GENERATION_LIMIT) {
        const now = new Date();
        const cooldownEnd = new Date(now.getTime() + COOLDOWN_MINUTES * 60 * 1000);
        setNextAvailableTime(cooldownEnd);
      }
      return newCount;
    });
  };

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    return `${minutes}m ${seconds}s`;
  };

  const clearHistory = () => {
    setHistory([]);
    localStorage.removeItem('history');
  };

  return (
    <ThemeProvider theme={theme}>
      <Box 
        sx={{ 
          width: '100%', 
          maxWidth: '600px', 
          mx: 'auto',
          position: 'relative',
          overflow: 'hidden',
          borderRadius: '24px',
          padding: isMobile ? '10px' : '20px',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'linear-gradient(135deg, #004D40 0%, #00695C 100%)',
            zIndex: -1,
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            top: -50,
            left: -50,
            right: -50,
            bottom: -50,
            background: 'url("/placeholder.svg?height=700&width=700") center/cover no-repeat',
            opacity: 0.1,
            animation: 'twinkling 8s infinite',
            zIndex: -1,
          },
          '@keyframes twinkling': {
            '0%': { opacity: 0.1 },
            '50%': { opacity: 0.3 },
            '100%': { opacity: 0.1 },
          },
        }}
      >
        <Zoom in={true} style={{ transitionDelay: '300ms' }}>
          <Paper 
            elevation={6} 
            sx={{ 
              p: isMobile ? 2 : 4, 
              borderRadius: '24px', 
              overflow: 'hidden', 
              position: 'relative',
              background: 'rgb(4 31 46)',
              backdropFilter: 'blur(10px)',
              border: '1px solid rgba(105, 240, 174, 0.1)',
            }}
          >
            <Typography variant={isMobile ? "h5" : "h4"} component="h1" gutterBottom align="center" sx={{ color: 'text.primary', mb: 4 }}>
              Generador de Números Cósmicos
            </Typography>
            <Grid container spacing={2} sx={{ mb: 4 }}>
              {cosmicItems.map((item, index) => (
                <Grid item xs={6} key={`cosmic-item-${index}`}>
                  <Fade in={true} style={{ transitionDelay: `${index * 100}ms` }}>
                    <StyledPaper elevation={6}>
                      <CosmicItem item={item} isRolling={isRolling} isMobile={isMobile} />
                    </StyledPaper>
                  </Fade>
                </Grid>
              ))}
            </Grid>

            <Button 
              variant="contained" 
              color="secondary" 
              fullWidth 
              onClick={generateCosmicItems}
              disabled={isGenerating || generationCount >= GENERATION_LIMIT || Boolean(nextAvailableTime)}
              startIcon={isGenerating ? <CircularProgress size={24} color="inherit" /> : <Casino />}
              sx={{ 
                py: isMobile ? 1.5 : 2, 
                fontSize: isMobile ? '1rem' : '1.3rem', 
                fontWeight: 'bold',
                color: '#004D40',
                backgroundColor: '#69F0AE',
                '&:disabled': {
                  bgcolor: 'rgba(255, 255, 255, 0.12)',
                  color: 'rgba(255, 255, 255, 0.3)',
                },
                '&:not(:disabled):hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 6px 12px 0 rgba(105, 240, 174, 0.3)',
                  backgroundColor: '#81C784',
                },
                transition: 'all 0.3s ease-in-out',
              }}
            >
              {isGenerating ? 'Invocando...' : 'Invocar Números Cósmicos'}
            </Button>

            {remainingTime && (
              <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <AccessTimeOutlined sx={{ mr: 1, color: 'secondary.light' }} />
                <Typography variant={isMobile ? "body2" : "body1"} sx={{ color: 'secondary.light' }}>
                  Próxima invocación en: {formatTime(remainingTime)}
                </Typography>
              </Box>
            )}

            <Box sx={{ mt: 4 }}>
              <Typography variant={isMobile ? "subtitle1" : "h6"} gutterBottom sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: 'text.primary' }}>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <HistoryOutlined sx={{ mr: 1 }} />
                  Registro Cósmico:
                </span>
                <Tooltip title="Limpiar registro" arrow>
                  <span>
                    <IconButton
                      onClick={clearHistory}
                      disabled={history.length === 0}
                      sx={{
                        color: 'secondary.main',
                        '&:hover': {
                          backgroundColor: 'rgba(105, 240, 174, 0.08)',
                        },
                      }}
                    >
                      <Refresh />
                    </IconButton>
                  </span>
                </Tooltip>
              </Typography>
              <List>
                {history.map((items, index) => (
                  <Fade in={true} key={index} style={{ transitionDelay: `${index * 100}ms` }}>
                    <ListItem sx={{
                      bgcolor: 'rgba(0, 200, 83, 0.1)', 
                      borderRadius: '12px', 
                      mb: 1,
                      transition: 'all 0.3s ease-in-out',
                      '&:hover': {
                        bgcolor: 'rgba(0, 200, 83, 0.2)',
                        transform: 'translateX(10px)',
                      },
                    }}>
                      <ListItemText 
                        primary={
                          <Box display="flex" alignItems="center" flexWrap="wrap">
                            {items.map((item, idx) => (
                              <Box key={idx} mr={1} mb={1}>
                                {typeof item === 'string' ? (
                                  <Typography 
                                    component="span" 
                                    sx={{ 
                                      fontWeight: 'bold',
                                      color: '#69F0AE',
                                      fontSize: isMobile ? '1.2rem' : '1.78rem',
                                      textShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
                                    }}
                                  >
                                    {item}
                                  </Typography>
                                ) : (
                                  <Tooltip title={item.description} arrow>
                                    <Box>
                                      <item.icon sx={{ fontSize: isMobile ? 20 : 24, color: '#69F0AE' }} />
                                    </Box>
                                  </Tooltip>
                                )}
                              </Box>
                            ))}
                          </Box>
                        }
                        secondary={`Invocación #${history.length - index}`}
                        sx={{
                          '& .MuiListItemText-primary': { 
                            fontWeight: 'bold',
                            color: 'text.primary'
                          },
                          '& .MuiListItemText-secondary': {
                            color: 'text.secondary',
                            fontSize: isMobile ? '0.75rem' : '0.875rem'
                          }
                        }}
                      />
                    </ListItem>
                  </Fade>
                ))}
              </List>
            </Box>
          </Paper>
        </Zoom>
      </Box>
    </ThemeProvider>
  );
}