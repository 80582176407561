import React, { useState, useEffect, useCallback } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Box, Tooltip, useMediaQuery, useTheme, Button } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ShareIcon from '@mui/icons-material/Share';
import CasinoIcon from '@mui/icons-material/Casino';
import { Link } from 'react-router-dom';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  background: `linear-gradient(45deg, rgba(26, 26, 26, 0.8), rgba(44, 44, 44, 0.8))`,
  backdropFilter: 'blur(10px)',
  overflow: 'hidden',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  borderBottom: '1px solid rgb(95 107 120)',
}));

const appear = keyframes`
  0% { transform: scale(0) rotate(0deg); opacity: 0; }
  100% { transform: scale(1) rotate(360deg); opacity: 1; }
`;

const move = keyframes`
  0% { transform: translate(0, 0); }
  25% { transform: translate(10px, 10px); }
  50% { transform: translate(0, 20px); }
  75% { transform: translate(-10px, 10px); }
  100% { transform: translate(0, 0); }
`;

const BowlingBall = styled('div')(({ theme }) => ({
  width: '36px',
  height: '36px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  fontSize: '0.8rem',
  position: 'absolute',
  animation: `${appear} 0.5s ease-out, ${move} 10s ease-in-out infinite`,
  boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'scale(1.1)',
    boxShadow: '0 6px 12px rgba(0,0,0,0.4)',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '5px',
    left: '5px',
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '8px',
    right: '8px',
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  }
}));

const BallContainer = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  overflow: 'hidden',
});

const fireAnimation = keyframes`
  0% { transform: scale(1.0) translate(0, 0); opacity: 0.4; }
  25% { transform: scale(1.05) translate(-2px, -2px); opacity: 0.5; }
  50% { transform: scale(1.1) translate(2px, -4px); opacity: 0.6; }
  75% { transform: scale(1.05) translate(-2px, -2px); opacity: 0.5; }
  100% { transform: scale(1.0) translate(0, 0); opacity: 0.4; }
`;

const FireEffect = styled('div')({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  height: '100%',
  background: 'linear-gradient(to top, rgba(0, 128, 128, 0.2) 0%, rgba(64, 224, 208, 0.1) 50%, rgba(0, 255, 255, 0.05) 100%)',
  animation: `${fireAnimation} 4s ease-in-out infinite`,
  pointerEvents: 'none',
  '&::before': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '40%',
    background: 'linear-gradient(to top, rgba(0, 128, 128, 0.3) 0%, rgba(0, 255, 255, 0.1) 100%)',
    filter: 'blur(4px)',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '60%',
    background: 'radial-gradient(ellipse at center, rgba(0, 206, 209, 0.2) 0%, transparent 70%)',
    filter: 'blur(8px)',
    transform: 'translateY(50%)',
    animation: `${fireAnimation} 6s ease-in-out infinite reverse`,
  }
});

const getRandomColor = () => {
  const colors = ['#FFD700', '#FF4500', '#32CD32', '#1E90FF', '#FF1493'];
  return colors[Math.floor(Math.random() * colors.length)];
};

const AnimatedDiceIcon = styled(CasinoIcon)(({ theme }) => ({
  animation: `${keyframes`
    0% { transform: rotate(0deg); }
    25% { transform: rotate(90deg); }
    50% { transform: rotate(180deg); }
    75% { transform: rotate(270deg); }
    100% { transform: rotate(360deg); }
  `} 2s linear infinite`,
}));

const GlassButton = styled(Button)(({ theme }) => ({
  color: '#ffffff',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(5px)',
  borderRadius: '20px',
  padding: '6px 16px',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
  },
}));

const GlassIconButton = styled(IconButton)(({ theme }) => ({
  color: '#ffffff',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(5px)',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
  },
}));

const Navbar = ({ toggleSidebar, isSidebarOpen }) => {
  const [balls, setBalls] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const createBall = useCallback(() => ({
    number: Math.floor(Math.random() * 100).toString().padStart(2, '0'),
    delay: `${Math.random() * 5}s`,
    duration: `${10 + Math.random() * 5}s`,
    xPosition: `${Math.random() * 100}%`,
    yPosition: `${Math.random() * 100}%`,
    color: getRandomColor(),
  }), []);

  const updateBalls = useCallback(() => {
    const ballCount = isMobile ? 5 : 8;
    setBalls(prevBalls => {
      const newBalls = [...prevBalls];
      while (newBalls.length < ballCount) {
        newBalls.push(createBall());
      }
      return newBalls.slice(-ballCount);
    });
  }, [isMobile, createBall]);

  useEffect(() => {
    updateBalls();
    const interval = setInterval(updateBalls, 2000);
    return () => clearInterval(interval);
  }, [updateBalls]);

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: 'TutaWin',
        url: window.location.href,
      }).then(() => {
        console.log('Thanks for sharing!');
      }).catch(console.error);
    } else {
      navigator.clipboard.writeText(window.location.href).then(() => {
        alert('Link copied to clipboard!');
      }).catch(console.error);
    }
  };

  const handleToggleSidebar = () => {
    if (typeof toggleSidebar === 'function') {
      toggleSidebar();
    }
  };

  return (
    <StyledAppBar position="fixed">
      <Toolbar sx={{ justifyContent: 'space-between', padding: 0, minHeight: 64, position: 'relative' }}>
        <BallContainer>
          {balls.map((ball, index) => (
            <BowlingBall
              key={index}
              style={{
                animationDelay: ball.delay,
                animationDuration: ball.duration,
                left: ball.xPosition,
                top: ball.yPosition,
                backgroundColor: ball.color,
                color: '#ffffff',
              }}
            >
              {ball.number}
            </BowlingBall>
          ))}
        </BallContainer>
        <Box sx={{ display: 'flex', alignItems: 'center', zIndex: 1 }}>
          <GlassIconButton
            aria-label={isSidebarOpen ? "close drawer" : "open drawer"}
            edge="start"
            onClick={handleToggleSidebar}
            sx={{ ml: 1 }}
          >
            {isSidebarOpen ? <ChevronLeftIcon /> : <MenuIcon />}
          </GlassIconButton>
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Typography 
              variant="h5" 
              noWrap 
              component="div" 
              sx={{ 
                ml: 1, 
                fontWeight: 'bold', 
                textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
                background: 'linear-gradient(45deg, #004d40, #96f069)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                cursor: 'pointer',
              }}
            >
              TutaWin
            </Typography>
          </Link>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: 'flex', alignItems: 'center', zIndex: 1 }}>
          <Tooltip title="Generador de numeros">
            <GlassButton
              component={Link}
              to="/gna"
              startIcon={<AnimatedDiceIcon />}
              sx={{ mr: 2 }}
            >
              Generador
            </GlassButton>
          </Tooltip>
          <Tooltip title="Share">
            <GlassIconButton 
              aria-label="share" 
              onClick={handleShare} 
              sx={{ mr: 1 }}
            >
              <ShareIcon />
            </GlassIconButton>
          </Tooltip>
        </Box>
      </Toolbar>
      <FireEffect />
    </StyledAppBar>
  );
};

export default Navbar;