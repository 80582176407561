import React, { useState, useEffect } from 'react';
import { Typography, Alert, Grid, Box, Card, CardContent, Container, useTheme, Skeleton, Fade } from '@mui/material';
import { styled } from '@mui/material/styles';
import { fetchAllLotteries } from '../services/api';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import StarIcon from '@mui/icons-material/Star';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import BoltIcon from '@mui/icons-material/Bolt';

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'gradientColors',
})(({ theme, gradientColors }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadius,
  background: gradientColors ? `linear-gradient(125deg, ${gradientColors[0]}, ${gradientColors[1]})` : theme.palette.background.paper,
  boxShadow: theme.customShadows?.card || theme.shadows[1],
  transition: theme.customTransitions?.medium || theme.transitions.create('all', {
    duration: theme.transitions.duration.standard,
  }),
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.customShadows?.cardHover || theme.shadows[4],
  },
}));

const NumberBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const LotteryNumber = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  fontSize: '1.2rem',
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
  transition: theme.customTransitions?.fast || theme.transitions.create('all', {
    duration: theme.transitions.duration.shortest,
  }),
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

const DateDisplay = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: theme.spacing(2),
  color: theme.palette.common.white,
  fontSize: '0.9rem',
  fontWeight: 'medium',
}));

const SkeletonCard = () => (
  <Card>
    <Skeleton variant="rectangular" height={200} />
  </Card>
);

const lotteryIcons = [
  <StarIcon key="star" />,
  <EmojiEventsIcon key="events" />,
  <WhatshotIcon key="hot" />,
  <BoltIcon key="bolt" />,
];

const gradientPairs = [
  ['#FF6B6B', '#4ECDC4'],
  ['#A770EF', '#CF8BF3'],
  ['#00B4DB', '#0083B0'],
  ['#FFA751', '#FFE259'],
  ['#ED4264', '#FFEDBC'],
  ['#614385', '#516395'],
];

export default function Home() {
  const [lotteryResults, setLotteryResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const results = await fetchAllLotteries();
        setLotteryResults(results);
      } catch (error) {
        setError('Error al obtener los resultados de la lotería. Inténtalo más tarde.');
        console.error('Error fetching lottery results:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return 'Fecha no disponible';
    try {
      const [day, month, year] = dateString.split('-');
      const date = new Date(year, month - 1, day);
      return date.toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric' });
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Fecha inválida';
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography 
        variant="h2" 
        component="h1"
        gutterBottom 
        align="center" 
        sx={{ 
          fontWeight: 700, 
          color: theme.palette.common.white, // Cambiado a blanco para el fondo oscuro
          mb: 4,
          fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
        }}
      >
        Resultados de Lotería
      </Typography>

      {loading ? (
        <Grid container spacing={3}>
          {[...Array(6)].map((_, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <SkeletonCard />
            </Grid>
          ))}
        </Grid>
      ) : error ? (
        <Alert 
          severity="error" 
          sx={{ 
            mt: 2, 
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[1],
          }}
        >
          {error}
        </Alert>
      ) : (
        <Grid container spacing={3}>
          {lotteryResults.map((result, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Fade in={true} style={{ transitionDelay: `${index * 100}ms` }}>
                <StyledCard gradientColors={gradientPairs[index % gradientPairs.length]}>
                  <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      {React.cloneElement(lotteryIcons[index % lotteryIcons.length], { sx: { color: 'white', mr: 1, fontSize: '2rem' } })}
                      <Typography variant="h6" component="h2" sx={{ color: 'white', fontWeight: 'bold' }}>
                        {result.name}
                      </Typography>
                    </Box>
                    <NumberBox>
                      {result.numbers && result.numbers.map((number, idx) => (
                        <LotteryNumber key={idx}>
                          {number}
                        </LotteryNumber>
                      ))}
                    </NumberBox>
                    <DateDisplay>
                      <CalendarTodayIcon sx={{ fontSize: 18, mr: 1 }} />
                      {formatDate(result.date)}
                    </DateDisplay>
                  </CardContent>
                </StyledCard>
              </Fade>
            </Grid>
          ))}
        </Grid>
      )}

      <Box sx={{ mt: 6, textAlign: 'center' }}>
        <Typography variant="body2" sx={{ color: theme.palette.common.white }}> {/* Ajustado a blanco */}
          Juega con responsabilidad. Los juegos de azar deben ser solo para entretenimiento.
        </Typography>
        <Typography variant="body2" sx={{ color: theme.palette.common.white, mt: 1 }}> {/* Ajustado a blanco */}
          Si tienes problemas con el juego, busca ayuda profesional.
        </Typography>
      </Box>
    </Container>
  );
}
