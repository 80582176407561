import React, { useState, useEffect } from 'react';
import { Typography, Alert, Grid, Card, CardContent, Box, Skeleton, useMediaQuery, useTheme, Divider, Paper, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getQuinielonDia, getPrimeraNoche, getPrimeraDia, getLoto5, getQuinielonNoche } from '../services/api';
import CasinoIcon from '@mui/icons-material/Casino';
import EventIcon from '@mui/icons-material/Event';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import InfoIcon from '@mui/icons-material/Info';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease-in-out',
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  background: 'linear-gradient(145deg, #ffffff, #f0f0f0)',
  boxShadow: '2px 2px 5px #d1d1d1, -2px -2px 5px #ffffff', // Reduced shadow intensity
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '4px 4px 10px #c1c1c1, -4px -4px 10px #ffffff', // Reduced hover shadow intensity
  },
}));

const CardHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  background: 'linear-gradient(45deg, #FF6B6B, #FF8E53)',
  color: theme.palette.common.white,
  textAlign: 'center',
}));

const ResultNumber = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40px',
  height: '40px',
  margin: theme.spacing(0.5),
  fontSize: '1.2rem',
  fontWeight: 'bold',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'scale(1.1)',
    backgroundColor: theme.palette.primary.dark,
  },
}));

const DateChip = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  padding: theme.spacing(0.5, 1),
  marginTop: theme.spacing(2),
  backgroundColor: '#f0f0f0',
  border: '1px dashed #FF6B6B',
  borderRadius: '16px',
  color: theme.palette.text.primary,
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(0.5),
  },
}));

const InfoPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.spacing(2),
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[10],
  },
}));

const GameTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
}));

const LaPrimera = () => {
  const [results, setResults] = useState({
    quinielonDia: [],
    primeraNoche: [],
    primeraDia: [],
    loto5: [],
    quinielonNoche: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const [quinielonDia, primeraNoche, primeraDia, loto5, quinielonNoche] = await Promise.all([
          getQuinielonDia(),
          getPrimeraNoche(),
          getPrimeraDia(),
          getLoto5(),
          getQuinielonNoche(),
        ]);
        setResults({ quinielonDia, primeraNoche, primeraDia, loto5, quinielonNoche });
      } catch (error) {
        setError('Error al obtener los resultados de La Primera. Intenta nuevamente más tarde.');
        console.error('Error fetching La Primera results:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, []);

  const renderLotteryCard = (title, data) => (
    <Grid item xs={12} sm={6} lg={4}>
      <StyledCard>
        <CardHeader>
          <Typography variant="h6" component="h2" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CasinoIcon sx={{ mr: 1 }} />
            {title}
          </Typography>
        </CardHeader>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
          {loading ? (
            <>
              <Skeleton variant="text" width="60%" height={24} />
              <Skeleton variant="text" width="40%" height={24} />
              <Box display="flex" flexWrap="wrap" justifyContent="center" mt={2}>
                {[...Array(5)].map((_, idx) => (
                  <Skeleton key={idx} variant="rectangular" width={40} height={40} style={{ margin: 4, borderRadius: '8px' }} />
                ))}
              </Box>
            </>
          ) : data.length === 0 ? (
            <Typography variant="body2">No hay resultados disponibles.</Typography>
          ) : (
            data.map((result, index) => (
              <Box key={index} mb={3} sx={{ textAlign: 'center', width: '100%' }}>
                <Typography variant="subtitle1" color="primary" gutterBottom sx={{ fontWeight: 'bold' }}>
                  {result.name}
                </Typography>
                <Box display="flex" flexWrap="wrap" justifyContent="center" mb={2}>
                  {result.numbers.map((number, idx) => (
                    <ResultNumber key={idx} sx={{ fontSize: isSmallScreen ? '1rem' : '1.2rem' }}>
                      {number}
                    </ResultNumber>
                  ))}
                </Box>
                <DateChip>
                  <EventIcon fontSize="small" />
                  <Typography variant="caption">{result.date}</Typography>
                </DateChip>
              </Box>
            ))
          )}
        </CardContent>
      </StyledCard>
    </Grid>
  );

  const renderHowToPlay = () => {
    const gameInfo = [
      {
        title: "El Quinielón Día",
        description: "Sorteo diurno de La Primera con grandes premios.",
        howToPlay: [
          "Elige un número de 2 cifras del 00 al 99.",
          "Decide el monto de tu apuesta.",
          "Espera el sorteo diurno para ver si tu número resulta ganador."
        ],
        prizes: [
          "Premio mayor por acertar el número exacto.",
          "Premios menores por acertar los últimos dígitos."
        ]
      },
      {
        title: "Primera Noche",
        description: "Sorteo nocturno de La Primera con emocionantes premios.",
        howToPlay: [
          "Selecciona un número de 2 cifras del 00 al 99.",
          "Realiza tu apuesta.",
          "Aguarda el sorteo nocturno para conocer los resultados."
        ],
        prizes: [
          "Gran premio por acertar el número completo.",
          "Premios adicionales por coincidencias parciales."
        ]
      },
      {
        title: "La Primera Día",
        description: "Sorteo diurno principal de La Primera.",
        howToPlay: [
          "Escoge un número de 2 cifras del 00 al 99.",
          "Determina el monto que deseas apostar.",
          "Espera el sorteo diurno para ver si has ganado."
        ],
        prizes: [
          "Premio principal por acertar el número exacto.",
          "Premios secundarios por coincidencias parciales."
        ]
      },
      {
        title: "Loto 5",
        description: "Emocionante juego de lotería con 5 números.",
        howToPlay: [
          "Selecciona 5 números del 1 al 39.",
          "Marca tus selecciones en el boleto.",
          "Espera el sorteo para ver cuántos números aciertas."
        ],
        prizes: [
          "Gran premio por acertar los 5 números.",
          "Premios menores por acertar 3 o 4 números."
        ]
      },
      {
        title: "El Quinielón Noche",
        description: "Sorteo nocturno de La Primera con atractivos premios.",
        howToPlay: [
          "Elige un número de 2 cifras del 00 al 99.",
          "Decide cuánto quieres apostar.",
          "Aguarda el sorteo nocturno para conocer los resultados."
        ],
        prizes: [
          "Premio mayor por acertar el número completo.",
          "Premios adicionales por coincidencias en los últimos dígitos."
        ]
      }
    ];

    return (
      <Box mt={6}>
        <Typography variant="h4" gutterBottom sx={{ color: theme.palette.primary.main, textAlign: 'center', mb: 4 }}>
          Cómo Jugar
        </Typography>
        <Grid container spacing={4}>
          {gameInfo.map((game, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <InfoPaper elevation={3}>
                <GameTitle variant="h5">
                  <CasinoIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                  {game.title}
                </GameTitle>
                <Typography variant="body2" paragraph>
                  {game.description}
                </Typography>
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.secondary.main }}>
                  ¿Cómo jugar?
                </Typography>
                <List dense>
                  {game.howToPlay.map((step, idx) => (
                    <ListItem key={idx}>
                      <ListItemIcon>
                        {idx === 0 ? <LooksOneIcon color="primary" /> :
                         idx === 1 ? <LooksTwoIcon color="primary" /> :
                         idx === 2 ? <Looks3Icon color="primary" /> :
                         idx === 3 ? <Looks4Icon color="primary" /> :
                         <Looks5Icon color="primary" />}
                      </ListItemIcon>
                      <ListItemText primary={step} />
                    </ListItem>
                  ))}
                </List>
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.secondary.main, mt: 2 }}>
                  Premios:
                </Typography>
                <List dense>
                  {game.prizes.map((prize, idx) => (
                    <ListItem key={idx}>
                      <ListItemIcon>
                        <EmojiEventsIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText primary={prize} />
                    </ListItem>
                  ))}
                </List>
              </InfoPaper>
            </Grid>
          ))}
        </Grid>
        <Box mt={4}>
          <InfoPaper elevation={3}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
              <InfoIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
              Consejos para Jugar Responsablemente
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <AttachMoneyIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Establece un presupuesto" 
                  secondary="Decide cuánto puedes gastar en lotería y no excedas ese límite."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LooksOneIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Juega por diversión" 
                  secondary="Recuerda que la lotería es una forma de entretenimiento, no una estrategia financiera."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <EmojiEventsIcon color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Conoce las probabilidades" 
                  secondary="Entiende las posibilidades reales de ganar en cada juego de La Primera."
                />
              </ListItem>
            </List>
          </InfoPaper>
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ flex: 1, width: '100%', maxWidth: '1200px', margin: '0 auto', p: { xs: 2, sm: 3 } }}>
      <Typography 
        variant="h4" 
        gutterBottom 
        sx={{ 
          mb: 4, 
          fontWeight: 'bold', 
          color: '#FF6B6B', 
          textAlign: 'center', 
          fontSize: { xs: '1.5rem', sm: '2rem' },
          textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
        }}
      >
        Resultados de La Primera
      </Typography>
      {error ? (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      ) : (
        <Grid container spacing={3} justifyContent="center">
          {renderLotteryCard('El Quinielón Día', results.quinielonDia)}
          {renderLotteryCard('Primera Noche', results.primeraNoche)}
          {renderLotteryCard('La Primera Día', results.primeraDia)}
          {renderLotteryCard('Loto 5', results.loto5)}
          {renderLotteryCard('El Quinielón Noche', results.quinielonNoche)}
        </Grid>
      )}
      <Divider sx={{ my: 6 }} />
      {renderHowToPlay()}
    </Box>
  );
};

export default LaPrimera;